<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          src="@/assets/images/profile-bg.jpg"
          class="profile-wid-img"
          alt=""/>
          <!-- <div class="overlay-content">
          <div class="text-end p-3">
            <div class="p-0 ms-auto rounded-circle profile-photo-edit">
              <input
                id="profile-foreground-img-file-input"
                class="profile-foreground-img-file-input"
              />
              <label
                for="profile-foreground-img-file-input"
                class="profile-photo-edit btn btn-light"
              >
                <i class="ri-image-edit-line align-bottom me-1"></i> Change
                Cover
              </label>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div
                class="
                  profile-user
                  position-relative
                  d-inline-block
                  mx-auto
                  mb-4
                "
              >
                <img
                  src="@/assets/images/users/avatar-1.jpg"
                  class="
                    rounded-circle
                    avatar-xl
                    img-thumbnail
                    user-profile-image
                  "
                  alt="user-profile-image"
                />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input
                    id="profile-img-file-input"
                    type="file"
                    class="profile-img-file-input"
                  />
                  <label
                    for="profile-img-file-input"
                    class="profile-photo-edit avatar-xs"
                  >
                    <span
                      class="avatar-title rounded-circle bg-light text-body"
                    >
                      <i class="ri-camera-fill"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h5 class="fs-16 mb-1">Anna Adame</h5>
              <p class="text-muted mb-0">Lead Designer / Developer</p>
            </div>
          </div>
        </div>
        <!--end card-->
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-5">
              <div class="flex-grow-1">
                <h5 class="card-title mb-0">Complete Your Profile</h5>
              </div>
              <div class="flex-shrink-0">
                <a
                  href="javascript:void(0);"
                  class="badge bg-light text-primary fs-12"
                  ><i class="ri-edit-box-line align-bottom me-1"></i> Edit</a
                >
              </div>
            </div>
            <div
              class="progress animated-progess custom-progress progress-label"
            >
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                style="width: 30%"
                aria-valuenow="30"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div class="label">30%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <div class="flex-grow-1">
                <h5 class="card-title mb-0">Portfolio</h5>
              </div>
              <div class="flex-shrink-0">
                <a
                  href="javascript:void(0);"
                  class="badge bg-light text-primary fs-12"
                  ><i class="ri-add-fill align-bottom me-1"></i> Add</a
                >
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span
                  class="avatar-title rounded-circle fs-16 bg-dark text-light"
                >
                  <i class="ri-github-fill"></i>
                </span>
              </div>
              <input
                type="email"
                class="form-control"
                id="gitUsername"
                placeholder="Username"
                value="@daveadame"
              />
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span class="avatar-title rounded-circle fs-16 bg-primary">
                  <i class="ri-global-fill"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                id="websiteInput"
                placeholder="www.example.com"
                value="www.velzon.com"
              />
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span class="avatar-title rounded-circle fs-16 bg-success">
                  <i class="ri-dribbble-fill"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                id="dribbleName"
                placeholder="Username"
                value="@dave_adame"
              />
            </div>
            <div class="d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span class="avatar-title rounded-circle fs-16 bg-danger">
                  <i class="ri-pinterest-fill"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                id="pinterestName"
                placeholder="Username"
                value="Advance Dave"
              />
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul
              class="
                nav nav-tabs-custom
                rounded
                card-header-tabs
                border-bottom-0
              "
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#personalDetails"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Personal Details
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#changePassword"
                  role="tab"
                >
                  <i class="far fa-user"></i>
                  Change Password
                </a>
              </li>
              <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#experience"
                  role="tab"
                >
                  <i class="far fa-envelope"></i>
                  Experience
                </a>
              </li> -->
              <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#privacy"
                  role="tab"
                >
                  <i class="far fa-envelope"></i>
                  Privacy Policy
                </a>
              </li> -->
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="personalDetails" role="tabpanel">
                <form @submit.prevent="userInfoUpdate">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstname" class="form-label"
                          >Nom</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.firstname"
                          id="firstname"
                          :class="{
                          'is-invalid': v$.user.firstname.$error && user.firstname || v$.user.firstname.$error && submited,
                          }"
                          placeholder="Votre Nom"
                        />
                        <div
                        v-for="(item, index) in v$.user.firstname.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.firstname || v$.user.firstname.$error && submited">{{ item.$message }}</span>
                      </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastname" class="form-label"
                          >Prénom</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.lastname"
                          :class="{
                          'is-invalid': v$.user.lastname.$error && user.lastname || v$.user.lastname.$error && submited,
                          }"
                          id="lastname"
                          placeholder="Votre Prénom"
                        />
                        <div
                        v-for="(item, index) in v$.user.lastname.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.lastname || v$.user.lastname.$error && submited">{{ item.$message }}</span>
                      </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="number" class="form-label"
                          >Numéro</label
                        >
                        <input
                          type="tel"
                          class="form-control"
                          v-model="user.number"
                          id="number"
                          :class="{
                          'is-invalid': v$.user.number.$error && user.number || v$.user.number.$error && submited,
                           }"
                          placeholder="Votre numero de téléphone"
                        />
                      <div
                        v-for="(item, index) in v$.user.number.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.number || v$.user.number.$error && submited">{{ item.$message }}</span>
                      </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="email" class="form-label"
                          >Email</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="user.email"
                          id="email"
                          :class="{
                          'is-invalid': v$.user.email.$error && user.email || v$.user.email.$error && submited}"
                          placeholder="Votre email"
                        />
                      <div
                        v-for="(item, index) in v$.user.email.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.email || v$.user.email.$error && submited">{{ item.$message }}</span>
                      </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label 
                        for="birthDate" 
                        class="form-label"
                          >Date de naissance</label
                        >
                    
                      <flat-pickr
                      class="form-control"
                      v-model="user.birthDate"
                      :class="{ 'is-invalid': v$.user.birthDate.$error && user.birthDate || v$.user.birthDate.$error && submited,}"
                      />
                      <div
                        v-for="(item, index) in v$.user.birthDate.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.birthDate || v$.user.birthDate.$error && submited">{{ item.$message }}</span>
                      </div>

                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="gender" class="form-label"
                          >Genre</label
                        >
                        <Multiselect
                          v-model="user.gender"
                          :class="{ 'is-invalid': v$.user.gender.$error && user.gender || v$.user.gender.$error && submited,}"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="[
                            { value: 'Homme', label: 'Homme' },
                            { value: 'Femme', label: 'Femme' },
                          ]"
                        />
                      <div
                        v-for="(item, index) in v$.user.gender.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.gender || v$.user.gender.$error && submited">{{ item.$message }}</span>
                      </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="designationInput" class="form-label"
                          >Adresse</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.address"
                          :class="{ 'is-invalid': v$.user.address.$error && user.address || v$.user.address.$error && submited,}"
                          id="designationInput"
                          placeholder="Votre Adresse"
                        />
                      <div
                        v-for="(item, index) in v$.user.address.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message && user.address || v$.user.address.$error && submited">{{ item.$message }}</span>
                      </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          Mettre à jour
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <form @submit.prevent="initSubmit">
                  <div class="row g-2">
                    <div class="col-lg-12">
                      <div>
                        <label for="oldpasswordInput" class="form-label"
                          >Mot de passe</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="oldpasswordInput"
                          placeholder="Entrer votre mot de passe"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div>
                        <label for="newpasswordInput" class="form-label"
                          >Nouveau mot de passe</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="newpasswordInput"
                          placeholder="Entrer votre nouveau mot de passe"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div>
                        <label for="confirmpasswordInput" class="form-label"
                          >Confirmez mot de passe</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="confirmpasswordInput"
                          placeholder="Confirmez le nouveau mot de passe"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <!-- <div class="col-lg-12">
                      <div class="mb-3">
                        <a
                          href="javascript:void(0);"
                          class="link-primary text-decoration-underline"
                          >Forgot Password ?</a
                        >
                      </div>
                    </div> -->
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-success">
                          Mettre à jour
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!-- <div class="mt-4 mb-3 border-bottom pb-2">
                  <div class="float-end">
                    <a href="javascript:void(0);" class="link-primary"
                      >All Logout</a
                    >
                  </div>
                  <h5 class="card-title">Login History</h5>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-shrink-0 avatar-sm">
                    <div
                      class="avatar-title bg-light text-primary rounded-3 fs-18"
                    >
                      <i class="ri-smartphone-line"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6>iPhone 12 Pro</h6>
                    <p class="text-muted mb-0">
                      Los Angeles, United States - March 16 at 2:47PM
                    </p>
                  </div>
                  <div>
                    <a href="javascript:void(0);">Logout</a>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-shrink-0 avatar-sm">
                    <div
                      class="avatar-title bg-light text-primary rounded-3 fs-18"
                    >
                      <i class="ri-tablet-line"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6>Apple iPad Pro</h6>
                    <p class="text-muted mb-0">
                      Washington, United States - November 06 at 10:43AM
                    </p>
                  </div>
                  <div>
                    <a href="javascript:void(0);">Logout</a>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-shrink-0 avatar-sm">
                    <div
                      class="avatar-title bg-light text-primary rounded-3 fs-18"
                    >
                      <i class="ri-smartphone-line"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6>Galaxy S21 Ultra 5G</h6>
                    <p class="text-muted mb-0">
                      Conneticut, United States - June 12 at 3:24PM
                    </p>
                  </div>
                  <div>
                    <a href="javascript:void(0);">Logout</a>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0 avatar-sm">
                    <div
                      class="avatar-title bg-light text-primary rounded-3 fs-18"
                    >
                      <i class="ri-macbook-line"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6>Dell Inspiron 14</h6>
                    <p class="text-muted mb-0">
                      Phoenix, United States - July 26 at 8:10AM
                    </p>
                  </div>
                  <div>
                    <a href="javascript:void(0);">Logout</a>
                  </div>
                </div> -->
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="experience" role="tabpanel">
                <form>
                  <div id="newlink">
                    <div id="1">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="mb-3">
                            <label for="jobTitle" class="form-label"
                              >Job Title</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="jobTitle"
                              placeholder="Job title"
                              value="Lead Designer / Developer"
                            />
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label for="companyName" class="form-label"
                              >Company Name</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="companyName"
                              placeholder="Company name"
                              value="Themesbrand"
                            />
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label for="experienceYear" class="form-label"
                              >Experience Years</label
                            >
                            <div class="row">
                              <div class="col-lg-5">
                                <Multiselect class="form-control"
                          v-model="value2"
                          :close-on-select="true"
                          :searchable="true"
                          :create-option="true"
                          :options="[
                            { value: '', label: 'Select years' },
                            { value: 'Choice 1', label: '2001' },
                            { value: 'Choice 2', label: '2002' },
                            { value: 'Choice 3', label: '2003' },
                            { value: 'Choice 4', label: '2004' },
                            { value: 'Choice 5', label: '2005' },
                            { value: 'Choice 6', label: '2006' },
                            { value: 'Choice 7', label: '2007' },
                            { value: 'Choice 8', label: '2008' },
                            { value: 'Choice 9', label: '2009' },
                            { value: 'Choice 10', label: '2010' },
                            { value: 'Choice 11', label: '2011' },
                            { value: 'Choice 12', label: '2012' },
                            { value: 'Choice 13', label: '2013' },
                            { value: 'Choice 14', label: '2014' },
                            { value: 'Choice 15', label: '2015' },
                            { value: 'Choice 16', label: '2016' },
                            { value: 'Choice 17', label: '2017' },
                            { value: 'Choice 18', label: '2018' },
                            { value: 'Choice 19', label: '2019' },
                            { value: 'Choice 20', label: '2020' },
                            { value: 'Choice 21', label: '2021' },
                            { value: 'Choice 22', label: '2022' }

                           
                          ]"
                        />
                              </div>
                              <!--end col-->
                              <div class="col-auto align-self-center">to</div>
                              <!--end col-->
                              <div class="col-lg-5">
                              

                                  <Multiselect class="form-control"
                          v-model="value1"
                          :close-on-select="true"
                          :searchable="true"
                          :create-option="true"
                          :options="[
                            { value: '', label: 'Select years' },
                            { value: 'Choice 1', label: '2001' },
                            { value: 'Choice 2', label: '2002' },
                            { value: 'Choice 3', label: '2003' },
                            { value: 'Choice 4', label: '2004' },
                            { value: 'Choice 5', label: '2005' },
                            { value: 'Choice 6', label: '2006' },
                            { value: 'Choice 7', label: '2007' },
                            { value: 'Choice 8', label: '2008' },
                            { value: 'Choice 9', label: '2009' },
                            { value: 'Choice 10', label: '2010' },
                            { value: 'Choice 11', label: '2011' },
                            { value: 'Choice 12', label: '2012' },
                            { value: 'Choice 13', label: '2013' },
                            { value: 'Choice 14', label: '2014' },
                            { value: 'Choice 15', label: '2015' },
                            { value: 'Choice 16', label: '2016' },
                            { value: 'Choice 17', label: '2017' },
                            { value: 'Choice 18', label: '2018' },
                            { value: 'Choice 19', label: '2019' },
                            { value: 'Choice 20', label: '2020' },
                            { value: 'Choice 21', label: '2021' },
                            { value: 'Choice 22', label: '2022' }

                           
                          ]"
                        />
                              </div>
                              <!--end col-->
                            </div>
                            <!--end row-->
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-lg-12">
                          <div class="mb-3">
                            <label for="jobDescription" class="form-label"
                              >Job Description</label
                            >
                            <textarea
                              class="form-control"
                              id="jobDescription"
                              rows="3"
                              placeholder="Enter description"
                            >
You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. </textarea
                            >
                          </div>
                        </div>
                        <!--end col-->
                        <div class="hstack gap-2 justify-content-end">
                          <a
                            class="btn btn-success"
                            href="javascript:deleteEl(1)"
                            >Delete</a
                          >
                        </div>
                      </div>
                      <!--end row-->
                    </div>
                  </div>
                  <div id="newForm" style="display: none"></div>
                  <div class="col-lg-12">
                    <div class="hstack gap-2">
                      <button type="submit" class="btn btn-success">
                        Update
                      </button>
                      <a href="javascript:new_link()" class="btn btn-primary"
                        >Add New</a
                      >
                    </div>
                  </div>
                  <!--end col-->
                </form>
              </div>
              <!--end tab-pane-->
               <!-- <div class="tab-pane" id="privacy" role="tabpanel">
                <div class="mb-4 pb-2">
                  <h5 class="card-title text-decoration-underline mb-3">
                    Security:
                  </h5>
                  <div class="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                    <div class="flex-grow-1">
                      <h6 class="fs-14 mb-1">Two-factor Authentication</h6>
                      <p class="text-muted">
                        Two-factor authentication is an enhanced security
                        meansur. Once enabled, you'll be required to give two
                        types of identification when you log into Google
                        Authentication and SMS are Supported.
                      </p>
                    </div>
                    <div class="flex-shrink-0 ms-sm-3">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-sm btn-primary"
                        >Enable Two-facor Authentication</a
                      >
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                    <div class="flex-grow-1">
                      <h6 class="fs-14 mb-1">Secondary Verification</h6>
                      <p class="text-muted">
                        The first factor is a password and the second commonly
                        includes a text with a code sent to your smartphone, or
                        biometrics using your fingerprint, face, or retina.
                      </p>
                    </div>
                    <div class="flex-shrink-0 ms-sm-3">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-sm btn-primary"
                        >Set up secondary method</a
                      >
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                    <div class="flex-grow-1">
                      <h6 class="fs-14 mb-1">Backup Codes</h6>
                      <p class="text-muted mb-sm-0">
                        A backup code is automatically generated for you when
                        you turn on two-factor authentication through your iOS
                        or Android Twitter app. You can also generate a backup
                        code on twitter.com.
                      </p>
                    </div>
                    <div class="flex-shrink-0 ms-sm-3">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-sm btn-primary"
                        >Generate backup codes</a
                      >
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <h5 class="card-title text-decoration-underline mb-3">
                    Application Notifications:
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="d-flex">
                      <div class="flex-grow-1">
                        <label
                          for="directMessage"
                          class="form-check-label fs-14"
                          >Direct messages</label
                        >
                        <p class="text-muted">
                          Messages from people you follow
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="directMessage"
                            checked
                          />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label
                          class="form-check-label fs-14"
                          for="desktopNotification"
                        >
                          Show desktop notifications
                        </label>
                        <p class="text-muted">
                          Choose the option you want as your default setting.
                          Block a site: Next to "Not allowed to send
                          notifications," click Add.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="desktopNotification"
                            checked
                          />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label
                          class="form-check-label fs-14"
                          for="emailNotification"
                        >
                          Show email notifications
                        </label>
                        <p class="text-muted">
                          Under Settings, choose Notifications. Under Select an
                          account, choose the account to enable notifications
                          for.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="emailNotification"
                          />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label
                          class="form-check-label fs-14"
                          for="chatNotification"
                        >
                          Show chat notifications
                        </label>
                        <p class="text-muted">
                          To prevent duplicate mobile notifications from the
                          Gmail and Chat apps, in settings, turn off Chat
                          notifications.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="chatNotification"
                          />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label
                          class="form-check-label fs-14"
                          for="purchaesNotification"
                        >
                          Show purchase notifications
                        </label>
                        <p class="text-muted">
                          Get real-time purchase alerts to protect yourself from
                          fraudulent charges.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="purchaesNotification"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 class="card-title text-decoration-underline mb-3">
                    Delete This Account:
                  </h5>
                  <p class="text-muted">
                    Go to the Data & Privacy section of your profile Account.
                    Scroll to "Your data & privacy options." Delete your Profile
                    Account. Follow the instructions to delete your account :
                  </p>
                  <div>
                    <input
                      type="password"
                      class="form-control"
                      id="passwordInput"
                      placeholder="Enter your password"
                      value="make@321654987"
                      style="max-width: 265px"
                    />
                  </div>
                  <div class="hstack gap-2 mt-3">
                    <a href="javascript:void(0);" class="btn btn-soft-danger"
                      >Close & Delete This Account</a
                    >
                    <a href="javascript:void(0);" class="btn btn-light"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div> -->
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import useVuelidate from "@vuelidate/core";
import { required, email, minLength, maxLength, helpers } from "@vuelidate/validators";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

export default {
  setup() {
      return { v$: useVuelidate() };
    },
  page: {
    title: "Setting",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {        
      user: {
          firstname: "bary",
          lastname: "allen",
          number: "98541254",
          gender: "Homme",
          email: "bary@gmail.com",
          birthDate:"12-01-2022",
          address:"Central city",
          password:"",
          new_password:"",
          new_password_confirm:"",
        }, 
      submited: false,
      title: "Setting",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Setting",
          active: true,
        },
      ],
      value: ['javascript'],
      date: null,
    };
  },
  validations: {
      user: {
        firstname: {
          required: helpers.withMessage("Veuillez entrer votre prenom", required),
          minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
          maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
          
        },
        lastname: {
          required: helpers.withMessage("Veuillez entrer votre nom", required),
          minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
          maxLength: helpers.withMessage("20 characteres maximum", maxLength(20)),
        },
        number: {
          minLength: helpers.withMessage("8 characteres minimum", minLength(8)),
          required: helpers.withMessage("Veuillez entrer votre numéro", required),
        },
        gender: {
          required: helpers.withMessage("Veuillez selectionnez un genre", required),
        },
        email: {
          required: helpers.withMessage("Veuillez entrer votre email", required),
          email: helpers.withMessage("Veuillez entrer un email valide", email),
        },
        birthDate:{
          required: helpers.withMessage("Veullez entrer votre date de naissance", required),
        },
        address: {
          required: helpers.withMessage("Veullez entrer votre adresse", required),
        }
      },
    },
    methods: {
      userInfoUpdate()
      {
        console.log('starting infos update');
        this.$store.dispatch('postRequest',{route:'/api/userInfoUpdate', data: this.user})
        // this.$store.dispatch('user')
        .then(
          function(response){
          console.log(response);
          },
          function(error){
            console.log(error);
          }
        )
     },
     userPasswordUpdate(){
      console.log('starting password update');
     }
    },
  components: {
    Layout,
    Multiselect,
    flatPickr
  }, 
  mounted(){
      this.v$.user.$touch();
    }
};
</script>
